@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400&display=swap');
.signupi{
    display: flex;
}
.input2{
    margin-left: 40px;
 
}
.homeimg2{
    height: 1000px;
}

.signupgoogle{
    padding: 12px !important;
}
.signupinputs{
    font-family:"Urbanist" !important;
}
.css-1nyqoj0-JoyAutocomplete-input {
    padding: 10px !important;
}
@media  (max-width:1400px) {
    .flname_main{
        gap:20px !important;
    }
    .flname{
        width: 47% !important;
    }
}
@media  (max-width:1200px) {
    .flname_main{
        gap:35px !important;
    }
}
@media  (max-width:991px) {
    .flname_main{
        gap:25px !important;
    }
}
@media  (max-width:767px) {
    .flname_main{
        gap:20px !important;
    }
}
@media  (max-width:535px) {
    .flname_main{
        gap:18px !important;
    }
}
@media  (max-width:487px) {
    .flname_main{
        gap:15px !important;
    }
}


@media  (max-width:416px) {
    .flname_main{
        gap:12px !important;
    }
    .reminderimg{
       display: none;
    }
    .signuplogo{
        width: 80%;
    }
}
@media  (max-width:345px) {
    .flname_main{
        gap:10px !important;
    }

}

@media  (max-width:1442px) {
    .reminderimg{
       /* display: none; */
    }
    .signuplogo{
        width: 505;
    }
    .firstnamesignup{
        width:60% !important;
        margin-left: 100px  !important;
    }
    .lastnamesignup{
        width: 60% !important;
    }
}

@media  (max-width:1358px) {
    .reminderimg{
       /* display: none; */
    }
    .signuplogo{
        width: 505;
    }
    .firstnamesignup{
        width:60% !important;
        margin-left: 69px  !important;
    }
    .lastnamesignup{
        width: 60% !important;
        margin-left: 15px !important;
    }
}

.reminderimgdiv{
    padding-right: 0px !important;
}
@media  (max-width:1199px) {
    .reminderimgdiv{
       display: none;
    }
    .signuplogo{
        width: 505;
    }
    .firstnamesignup{
        width:60% !important;
        margin-left: 141px  !important;
    }
    .lastnamesignup{
        width: 60% !important;
        margin-left: 39px !important;
    }
}

@media  (max-width:991px) {
    .reminderimgdiv{
       display: none;
    }
    .signuplogo{
        width: 505;
    }
    .firstnamesignup{
        width:60% !important;
        margin-left: 105px  !important;
    }
    .lastnamesignup{
        width: 60% !important;
        margin-left: 30px !important;
    }
}


@media  (max-width:767px) {
    .reminderimgdiv{
       display: none;
    }
    .signuplogo{
        width: 505;
    }
    .firstnamesignup{
        width:60% !important;
        margin-left: 80px  !important;
    }
    .lastnamesignup{
        width: 60% !important;
        margin-left: 20px !important;
    }
}

@media  (max-width:575px) {
    .reminderimgdiv{
       display: none;
    }
    .signuplogo{
        width: 505;
    }
    .firstnamesignup{
        width:30% !important;
        margin-left: 80px  !important;
    }
    .lastnamesignup{
        width: 30% !important;
        margin-left: 20px !important;
    }
}
@media  (max-width:767px) {
 .signuplogo{
    width: 50% !important;
 }
    }
