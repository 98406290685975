@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400&display=swap');

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected{
    color:#FF3333 !important;
  border-bottom: 1px solid #FF3333  !important;

}

.css-11yukd5-MuiTabs-indicator {
    background-color: #E5E5E5 !important;
}

.textfieldinput{
    border: none !important;
    border-radius: 10px !important;
  }
  #selectshow .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    background-color: #FF3333 !important;
    color: #FFFFFF !important;
    /* border-radius: 10px !important; */
    height: 30px !important;
  }
  #selectshow .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    display: flex !important;
    /* justify-content: left !important; */
    padding-left: 5px !important;
    padding-top: 20px !important;
  }
  #selectshow .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none !important;
  }
  .selecbtnshow{
    display: flex;
    align-items: center;
    background-color: #FF3333;
    padding: 10px 5px 10px 10px;
    border-radius: 25px;
    gap: 20px;
    color: #FFFFFF;
  }
  #sele .col-lg-1{
     width: 10.333333% !important;
  }
  #sele .col-lg-11{
     width: 89.666667% !important;
  }

  
.spantxt{
  font-size: 17px ;
  color: black;
  font-weight: 500;
  text-transform: uppercase;
  font-weight: 600 !important;

}
.spantxtactive{
  color: #FF3333;
  font-size: 17px ;
  font-weight: 500;
  text-transform: uppercase;
  font-weight: 600 !important;

 }
.spannum{
  font-size: 17px ;
  background-color: #00000018 !important;
  border-radius: 20px;
  padding: 5px 20px;
  color: black;
  font-weight: 600;
  
}
.spannumactive{
  font-size: 17px ;
  background-color: #ff333323 !important;
  border-radius: 20px;
  padding: 5px 20px;
  color: #FF3333;
  font-weight: 600;
}
#tab .nav-link{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
  border: none !important;
  background-color: #ff333300 !important;
  border-bottom: 2px solid #f1f5fa !important;
  font-family: 'Urbanist' !important;
}
#tab .nav-linkactive{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
  border: none !important;
  background-color: #ff333300 !important;
  border-bottom: 2px solid #f1f5fa !important;
}
#tab .css-heg063-MuiTabs-flexContainer {
  gap: 10px !important;
}


.css-1lyqkne-JoyInput-root{
  --Input-focusedHighlight:none !important;
}
.serchbar{
  font-family:"Urbanist" !important;
  padding: 10px;
}
.css-k06kcs-JoyInput-input{
  padding: 10px !important;
}
.selecbtnshow2{
  display: flex;
  align-items: center;
  background-color: #FF3333;
  padding: 10px 5px 10px 10px;
  border-radius: 25px;
  gap: 20px;
  color: #FFFFFF;
}
.dropdown2{
  width: 190px !important;
}
/* #selectshow2 .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  background-color: #FF3333 !important;
  color: #FFFFFF !important;
 
  height: auto !important;
}
#selectshow2 .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  display: flex !important;

  padding-left: 5px !important;
  padding-top: 20px !important;
} */
.dropdown2 .MuiSelect-select{
  display: flex !important;
 
  padding-left: 5px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  border: none !important;
  background-color: #FF3333 !important;
  color: #FFFFFF !important;
  border-radius: 25px !important;

  
}
#selectshow2 .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: none !important;
}
.loadbtn{
  font-family: "Urbanist" !important;
}
.discountselect{
  background-color: #FF3333;
  border: none ;
  outline: none;
  color: #E5E5E5;
  font-family: "Urbanist" !important;

}
.option{
  background-color: #E5E5E5;
  color: #000000;
}
.MuiTabs-flexContainer {
  overflow-x: auto !important;
}
.serchbardiscount_main{
  gap: 5px;
}
.dis_img{
  border: 5px solid white;
}

@media (max-width:995px) {
  .serchbardiscount{
    width: 72% !important;
  }
}
@media (max-width:768px) and (min-width: 763px){
  .serchbardiscount{
    width: 70% !important;
  }
}
@media (max-width:499px) and (min-width: 466px){
  .serchbardiscount{
    width: 70% !important;
  }
}
@media (max-width:466px) and (min-width: 450px){
  .serchbardiscount{
    width: 66% !important;

}
}
@media (max-width:449px) and (min-width: 410px){
  .serchbardiscount{
    width: 65% !important;

}
}
@media (max-width:415px) and (min-width: 365px){
  .serchbardiscount{
    width: 59% !important;

}
}
@media (max-width:365px) and (min-width: 335px){
  .serchbardiscount{
    width: 54% !important;

}
}

@media  (max-width:415px) {
   /* .serchbar2{
    width: 226px;
    height: 38px !important;
   } */
   .selecbtnshow2{
    margin-left: -96px;
    width: 124px !important;
    height: 38px !important;
   }
   #selectshow2.col-lg-1{
    width: 124px !important;
   }
   .loadbtn{
    width: 59% !important;
   }
   .dropdown2{
    width: 100px !important;
   }
}


@media (max-width:1199px)  {
  .serchbardiscount{
    width: 80% !important;
  }
}
@media (max-width:1195px)  {
  .serchbardiscount{
    width: 84% !important;
  }
}
@media (max-width:995px) {
  .serchbardiscount{
    width: 80% !important;
  }
}
@media (max-width:991px) {
  .serchbardiscount{
    width: 72% !important;
  }
}


@media (max-width: 1025px) and (min-width: 765px){
  /* .serchbar2{
    width: 526px;
   } */
   #selectshow2.col-lg-1{
    width: 124px !important;
    margin-left: -80px !important;
   }
}


