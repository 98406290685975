@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400&display=swap');

.speciallogo{
    margin-top: 80px;
    margin-bottom: 30px;
    width: 250px;
}
.inputfeild{
    font-family:"Urbanist" !important;
}
.passwrodfeild{
    font-family:"Urbanist" !important;
    padding: 10px;
    /* font-size: 44px !important; */
}
.login{
    background-color: #ffffff;
    width: 50%;
    height: 1000px;
    /* justify-content: center; */
    text-align: center;
    margin-left: 200px;
    border-radius: 10px;
}
.logintext{
    font-weight: 600;
    font-size: 30px;
    margin-left: -100px;
   
}
.input{
    margin-left: 200px;
}
.forgot{
    color: red;
    font-weight: 900;
    font-size: large;
    margin-left: -150px;
    margin-top: 50px;
}
.loginbtn{
    color: #ffffff;
    background-color: red;
    border: none;
    height: 50px;
    width: 440px;
    margin-left: -120px;
    border-radius: 10px;
    font-weight: 900;
    cursor: pointer;
}
.loginbtngl{
    color: black;
    background-color: #ffffff;
    border: none;
    height: 50px;
    width: 440px;
    margin-left: -120px;
    border-radius: 20px;
    border: 1px solid black;
    font-weight: 900;
   text-align: center;
   justify-content: center;
  
}
.policy{
    color: red;
    margin-top: -20px;
   
   
}
.here{
    color: red;
    font-weight: 600;
    cursor: pointer;
}
.privacy{
    color: black;
}
.photo{
    /* background-color: yellow; */
    width: 50%;
    height: 400px;
    margin-top: -1000px;
    margin-left: 850px;
    /* height: 400px; */
}
.homeimg{
    height: 100%;
    width: 100%;
}

.css-fdxl5t-JoyInput-input{
padding: 9px !important;
}
.logingoogle{
    padding: 12px !important;
}
.mainimg{
    width: 50%;
    margin-bottom: 20px;
}
.homediv{
    padding-right: 0px !important;
}
@media  (max-width:415px) {
    .mainimg{
        width: 80%;
    }
    .homediv{
        display: none;
    }

    .logindiv{
        margin-top: 60px;
    }
}
@media  (max-width:450px) {
    .logintxt{
        font-size: 12px !important;
    }

}
@media (min-width:416px) and (max-width:1024px) {
    .homediv{
        display: none;
    }
}

