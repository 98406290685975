.disablefashion {
  /* display: none;    */
  background-color:#DADADA;
  cursor: no-drop !important;
}
.claimbtn:hover {
  background-color: rgb(132, 204, 98);
}
.claimbtn {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
  cursor: auto;
}
.logoimg {
  /* background-color: #fff; */
  /* width: 100px;
  height: 100px;
  float: left;
  position: absolute; */
  /* margin-top: 45%;
  margin-left: 40%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 10px; */
}

@media (max-width:992px) {
.card_main{
  padding: 0% 10% 10% 10%;
}
.claimbtn{
  width: 200px !important;
  font-size: 15px !important;
}
}
@media (max-width:769px) {
.card_main{
  padding: 0% 5% 5% 5%;
}
.claimbtn{
  width: 150px !important;
  font-size: 12px !important;
}
.middel_imgbg{
  width: 130px !important;
  height: 80px !important;
}
.middel_img{
  width: 80px !important;
  height: 55px !important;
}
}
@media  (max-width:415px) {
.fashionimg{
  margin-left: -181px !important;
  margin-top: -270px !important;
}
.background{
  margin-left: -217px !important ;
  margin-top: -242px !important;
  height: 100px !important;
  width: 0px !important;
}
.middel_imgbg{
  width: 100px !important;
  height: 80px !important;
}
.middel_img{
  width: 80px !important;
  height: 50px !important;
}
}