.navbaricon{
  text-decoration: none;
  color: aqua;
}
.active {
  color: red !important;
}
.menubtn_main{
  display: none;
}
.main_nav{
  /* display: flex;
  justify-content: center; */
  width: 100% !important;
}
.nevbar_after_click{
  display: flex;
  align-items: center;
  justify-content: center;
}
.nevbar_after_click_under{
  display: flex;
  align-items: center;
  height: 80px;
}
.menu_active{
  display: block;
}
.main_nav_after_click_outside{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #00000058 !important;
}
.main_nav_after_click{
  position: fixed;
  width: 250px;
  height: 100%;
  background: #fff !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 50px;
  z-index: 999;
  justify-content: center !important;
}
.nav1{
  margin: 0px !important;
}
.logout_btn{
  margin-right: -40px;
}
.weblogo{
  /* width: 114% !important; */
  width: 160px!important;
}
.nav_main_username{
  cursor: pointer;
  display: flex !important;
  height: 100% !important;
  /* margin-top: -18px !important; */
}
.ddbtn_username_outside{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
.ddbtn_username{
  display: flex !important;
  /* gap: 6px !important; */
  justify-content: center !important;
  align-items: center !important;
  color: black !important;
  background-color: #ffffff00 !important;
  border-color: rgba(0, 0, 0, 0) !important;
}
.userimg{
  margin-left: 5PX;
    width: 50px !important;
}
.dpditem_username{
  cursor: auto !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  display: none !important;
}
@media (max-width:1397px) {
  .logout_btn{
    margin-left: -40px !important;
  }
  .userimg{
      width: 50px !important;
  }
}
@media (max-width:992px) {
  .menu_after_nav{
    margin: auto -8px !important;
  }
.nav_main_under{
  /* width: 110%; */
  /* gap: 10px; */
  margin-right: 10px !important;
}
.nav_main_username{
  width: 10% !important;
  gap: 10px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.nav_name{
  font-size: 12px;
}
.weblogo{
  width: 135px !important;
}
.userimg{
  width: 30px !important;
}
.logout_btn{
  width: 80px !important;
  height: 40px !important;
  font-size: 13px !important;
  /* margin-left: 10px !important; */
}
.ddbtn_username{
}
}
@media (max-width:769px) {
  .ddbtn_username_outside{
    justify-content: end !important;
  }
  .nav_name{
    display: none;
  }
  .dpditem_username{
    display: block !important;
  }
  .weblogo{
  margin-left: 10px;
      width: 172px !important;
  }
 .nav_main{
  /* margin-right: 50px !important; */
  /* padding: 0px !important; */
 }
  .main_nav{
      height: 80px !important;
      width: 100%;
  }
  .nav_main_under{
      display: flex;
      align-items: center;
      height: 80px !important;
      margin-left: 20% !important;
      /* margin-left: 0px !important; */
      /* width: 88% !important; */
      /* width: 585px !important; */
  }
  .menubtn_main{
      display: block;
      position: absolute;
      top: 25px;
      left: 16px;
      width: 50px ;
      cursor: pointer;
  }
  .menubtn{
      font-size: 30px !important;
    }
    .menu_after_nav{
      display: none;
    }
    .userimg{
      width: 40px !important;
  }
  .placeoflogo{
    text-align: end;
    margin-left: 158px !important;
  }
    .lg1btn {
      text-align: end;
      margin-right: 30px !important;
    }
  .logout_btn{
    margin-left: 0px !important;
  }
}
@media (max-width:766px) {
.nav_main_under{
  display: flex;
  align-items: center;
  height: 80px !important;
  margin-left: 0px !important;
  /* margin-left: 20% !important; */
  /* margin-left: 0px !important; */
  /* width: 88% !important; */
  /* width: 525px !important; */
}
}
@media (max-width:561px) {
.placeoflogo{
  text-align: end;
  margin-left: 170px !important;
}
  .lg1btn {
    text-align: end;
    margin-right: 30px !important;
  }
}
@media (max-width:540px) {
  .weblogo{
      width: 130px !important;
  }
  /* .nav_main{
      margin-right: 0px !important;
     } */
     .nav_main_under{
      margin-left: 17% !important;
     }
     .userimg{
      width: 30px !important;
  }
  .placeoflogo {
    text-align: end;
    margin-left: 75px !important;
}
.lg1btn{
  align-items: center;
  display: flex;
  margin: auto -32px;
  font-size: 20px;
  height: 60px;
  justify-content: end;
}
}
@media (max-width:450px ) {
  .nav_name{
    display: none;
  }
  .dpditem_username{
    display: block !important;
  }
}
@media (max-width:400px ) {
  .nav_main_under{
      margin-left: 10% !important;
     }
     .menubtn{
      font-size: 20px !important;
    }
}
@media (max-width:414px ) {
  .placeoflogo{
    margin-left: 45px !important;
  }
}
@media (max-width:412px ) {
  .menubtn_main{
    left: 10 !important;
  }
  .nav_main_under{
      margin-left: 10% !important;
     }
     .menubtn{
      font-size: 20px !important;
    }
    .logout_btn {
      width: 70px !important;
      height: 35px !important;
    }
    .menubtn_main{
      left: 5px;
    }
}

@media (max-width:413px ) and (min-width:411px) {
   .placeoflogo{
    margin-left: 70px !important;
  }
}
@media (max-width:394px ) and (min-width:380px) {
   .placeoflogo{
    margin-left: 65px !important;
  }
}
@media (max-width:769px ) and (min-width:760px) {
   .placeoflogo{
    margin-left: 65px !important;
  }
}
@media (max-width:380px ) {
  .nav_main_under{
      margin-left: 10% !important;
     }
     .menubtn{
      font-size: 20px !important;
    }
    .placeoflogo{
      margin-left: 55px !important;
    }
}





