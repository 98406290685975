.footerimg{
  
   display: flex !important;
   justify-content: flex-start !important;
}

@media  (max-width:415px) {
   .headings{
    /* margin-left: -50px !important; */
   }
   .footerimg{
      width: 222px !important;
      /* margin-left: -120px; */
      display: flex !important;
      justify-content: flex-start !important;
   }
  }
