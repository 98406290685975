.remindermaindiv{
    background-color: #E5E5E5;
    height: 100vh;
}

button {
    background-color: gray;
    color: white;
  }
  
button.clicked {
    background-color: #FF3333;
  }

button.clickedleft{
    background-color: #FF3333;
}  

.display{
  /* display: none; */
  background-color: #DADADA;
  cursor:no-drop !important;
}


@media  (max-width:415px) {
   .reminderimg{
    margin-left: 14px;
   }
   .reminderplusimg{
    margin-left: -21px;
   }
   .remindercalimg{
    margin-left: 10px;
   }
}