@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400&display=swap');


body {
  margin: 0;
  /* font-family:'Raleway',sans-serif !important;
   */
   font-family: 'Urbanist' !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F5FA !important;
}

code {
  /* font-family:'Raleway',sans-serif !important; */
  font-family: 'Urbanist' !important;

}
.w-fit{
  width: fit-content !important;
}