.header{
    background-color:#ffff;
    height: 90px;
    justify-content: center;
}

.dashlogo{
    float: left;
    width: 200px;
   
    margin-top: 1px;
    margin-left: 100px;
}
.navigation{
    gap: 10px !important;
    margin: 70px;
    /* margin-left: 20px !important; */

    
}
.ui{
    float: left;
    margin-left: 50px;
    /* justify-content: center; */
    margin-top: 30px;
    gap: 10px;
    justify-content: space-between;
}
.userphoto{
   /* margin-bottom: -10px;
   margin-left: 10px; */
   float: right;
   margin-right: 300px;
   position: absolute;
   margin-top: 0px;
   margin-left: 10px;
   /* padding: 10px    ; */

}
.username{
    text-align: justify;
    margin-left: 20px !important;
}

.hederbtn{
    color: white;
    background-color: red;
    border: none;
    border-radius: 20px;
    width: 100px;
    height: 50px;
    margin-left: 90px;
    font-weight: 600;
}
.box{
    color: #ffff;
    background-color: red;
    width: 1350px;
    margin-left: 310px;
    margin-top: 50px;
    height: 200px;
    border-radius: 20px;
}
.firstdiv{
    float: left;
    margin-left: 50px;

}
.seconddiv{
  float: left;
  margin-left: 250px;
}
.box2{
    color: #FF3333;
    background-color: #ffff;
    width: 1350px;
    margin-left: 250px;
    margin-top: 50px;
    height: 200px;
    border-radius: 20px;

}
.box2first{
    float: left;
    margin-left: 50px;
}
.box2second{
    float: left;
    margin-top: 80px;
    margin-left: -160px;
}
.box2third{
    float: left;
    margin-top: 100px;
    margin-left: -665px;
}
.logo{
    float: left;
    margin: 5px;
    background-color: rgb(224, 198, 198);
    height: 30px;
    width: 30px;
    border-radius: 10px;
    margin-top: -3px;
    /* justify-content: center; */
}
.alertlogo{
    margin-top: 7px;
}
.serch{
    margin-top: 50px;
    width: 1200px;
    margin-left: 200px;
    height: 40px;
    border-radius: 20px !important;
    border: none;
}
.serchbox{
    display: flex;
   
}
.serchbtn{
    width: 100px;
    height: 50px;
    margin-top: 40px;
    margin-left: 40px;
    border-radius: 10px;
    background-color: #FF3333;
    color: #ffff;
    border: none;
}
/* .dropdown{
   font-size: 20px;
   margin-top: -10px;
} */
.datatable{
    background-color: #ffff;
    width: 1350px;
    margin-left: 200px;
    margin-top: 50px;
    height: 100%;
    border-radius: 20px;

}
.data1{
    margin-left: -1000px;
    margin-top: 30px !important;
    
}

.info{
    margin-left: 180px;
    margin-top: 10px;
    
}
.data2{
    margin-top: -40px;
    margin-left: -300px;
}
.info2{
    margin-left: 120px;
}
.data3{
    margin-top: -50px;
    margin-left: 300px;
}
.info3{
    margin-left:120px;
}
.edit{
    padding: 10px;
    cursor: pointer;
}
.editsection{
    float: right;
    margin-right: 20px;
    margin-top: -40px;
    padding: 10px;
    /* width: 1000px; */
    border: none;
    border-radius: 10px;
    background-color: #F1F5FA;
    color: #FF3333;
}
.edit{
    margin-right: 10px;
}
.cake{
    margin-right: 30px;
    margin-top: 5px;
    /* color: #F1F5FA; */
    margin-left: 30px;
    /* border-radius: 50%; */
}
/* .cakediv{
   height: 50px !important;
   border-radius: 10%;
} */
.pagination{
    margin-top: 30px;
    margin-left: 700px;
}
.reminder{
    display: flex;
    margin-left: 200px;
    margin-top: 20px;
}
.reminderbtn{
    width: 425px;
    height: 50px;
    border-radius: 10px;
    background-color: #FF3333;
    color: #ffff;
    border: none;

}
.holidaybtn{
    width: 425px;
    height: 50px;
    border-radius: 10px;
    background-color: #ffff;
    color: #FF3333;
    border: 1px solid #FF3333;
    margin-left: 30px;
}
/* .dropdown{
    width: 100px;
    height: 50px;
    margin-top: 40px;
    margin-left: 10px;
    border-radius: 10px;
    background-color: #FF3333;
    color: #F1F5FA;
    border: none;
} */

/* //footer css */
.box3{
    color: #FF3333;
    background-color: #ffff;
    width: 100%;
    height: 300px;
    margin-top: 70px;
   
}
.footer{
    width: 200px;
   
}
.footerlogo{
    float: left;
    width: 200px;
   
    margin-top: 50px;
    margin-left: 200px;
}
.footertext{
    margin-left: -195px;
    float: left;
    margin-top: 110px !important; 
    color:#454545  ;
    /* font-weight: 400;   */
}
.sevices{
    /* margin-left: 100px; */
    float: left;
}
.serviceheading{
    margin-left: 100px;
    margin-top: 50px;
}

@media (max-width:375px) {
   
    }