@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400&display=swap');



.calendermaindiv{
    height: 100%;
    background-color: #F1F5FA !important;
    /* background-color: #6767679e !important; */
}
.calenderdropdown{
    width: 100%;
    border-radius: 20px;
    height: 100%;
    color: aliceblue;
    background-color: #FF3333;
    border: none    ;
    
}

.MuiTabs-flexContainer {
    overflow-x: auto !important;
}
/* .css-heg063-MuiTabs-flexContainer::-webkit-scrollbar{
    width: 50px !important;
 
} */

.tab {
    background-color: #ccc; /* Set the background color for all tabs */
    border: 1px solid #aaa; /* Add a border to all tabs */
    padding: 10px; /* Add some padding to the tabs */
  }
  
  .tab.active {
    background-color: #f00; /* Set the background color for the active tab */
    border-bottom-color: #f00; /* Change the bottom border color to match the background color */
  }

.tabcolor{
  /* border-bottom:1px solid red */
   border-bottom: 1px solid #FF3333 !important ;
}
/* 
.tablabel.active {
    border-bottom: 2px solid blue; 
  } */

/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color:red ;
  border-bottom: 1px solid #FF3333  ;
}  */

.css-1aquho2-MuiTabs-indicator{
  background-color: #E5E5E5 !important;
  
} 
.serchbarcalender_main{
  display: flex;
  align-items: center;
  /* width: 102%; */
  gap: 5px;
  /* margin-left: 0px !important; */
}
.tab {
  color: black;
  border-bottom: 2px solid transparent;
}

.tab.selected {
  color: red;
  border-bottom: 2px solid red;
}
.plusimg{
  margin-right:30px;
}
.textfieldinput{
  border: none !important;
  border-radius: 10px !important;
  width: 50px !important;
}
#selectshow .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  background-color: #FF3333 !important;
  color: #FFFFFF !important;
  /* border-radius: 10px !important; */
  height: 30px !important;
}
#selectshow .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  display: flex !important;
  /* justify-content: left !important; */
  padding-left: 5px !important;
  padding-top: 20px !important;
}
#selectshow .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: none !important;
}
.selecbtnshow{
  display: flex;
  align-items: center;
  background-color: #FF3333;
  padding: 10px 5px 10px 10px;
  border-radius: 25px;
  gap: 20px;
  color: #FFFFFF;
 
}
#sele .col-lg-1{
   width: 10.333333% !important;
}
#sele .col-lg-11{
   width: 89.666667% !important;
}

.spantxt{
  font-size: 17px ;
  color: black;
  font-weight: 500;
  text-transform: capitalize !important;
}
.spantxtactive{
  color: #FF3333;
  font-size: 17px ;
  font-weight: 500;
  text-transform: capitalize !important;
 }
.spannum{
  font-size: 17px ;
  background-color: #00000018 !important;
  border-radius: 20px;
  padding: 5px 15px;
  color: black;
  font-weight: 600;
}
.spannumactive{
  font-size: 17px ;
  background-color: #ff333323 !important;
  border-radius: 20px;
  padding: 5px 15px;
  color: #FF3333;
  font-weight: 600;
}
#tab .nav-link{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
  border: none !important;
  background-color: #ff333300 !important;
  /* border-bottom: 2px solid #ff333300 !important; */
}
#tab .nav-linkactive{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
  border: none !important;
  background-color: #ff333300 !important;
  /* border-bottom: 2px solid #ff3333ab !important; */
}
#tab .css-heg063-MuiTabs-flexContainer {
  gap: 10px !important;
}

.dropdown{
  width: 190px !important;
}

.css-1lyqkne-JoyInput-root{
  /* --Input-focusedHighlight: none; */
  --Input-focusedHighlight:none;
}
#selectshow{
  width: 172px !important;
}
.serchbar{
  font-family:"Urbanist" !important;
  padding: 10px;
  border-radius: 20px;
}
.css-k06kcs-JoyInput-input{
  padding: 10px !important;
}
/* .css-wjh20t-MuiPagination-ul{
  justify-content: center;
} */
.harsh .MuiPagination-ul {
  justify-content: center;
}
.MuiTabs-flexContainer::-webkit-scrollbar{
/* height: 5px; */
}
.tabsindicator .MuiTabs-indicator{
  position: absolute;
    height: 10px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    /* background-color: #1976d2; */
    left: 0px;
    width: 0px;
    background-color: #E5E5E5 !important;
}

.MuiTabs-indicator::-webkit-scrollbar {
  height: 18px !important; /* sets the height of the scrollbar */
}
.MuiTabs-flexContainer::-webkit-scrollbar {
  height: 5px !important; /* sets the height of the scrollbar */
}

.MuiTabs-flexContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important; /* sets the background color of the scrollbar track */
}

.MuiTabs-flexContainer::-webkit-scrollbar-thumb {
  background-color: #888 !important; /* sets the color of the scrollbar thumb */
  border-radius: 4px; /* adds rounded corners to the scrollbar thumb */
}

.MuiTabs-flexContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555 !important; /* sets the color of the scrollbar thumb on hover */
}
.text{
  font-size: 1.2rem !important;
}
/* .MuiTabs-flexContainer::-webkit-scrollbar-button:start:decrement,
.MuiTabs-flexContainer::-webkit-scrollbar-button:end:increment {
  height: 30px;
  width: 30px;
  display: block;
  background-color: #ccc;
}

.MuiTabs-flexContainer::-webkit-scrollbar-button:start:decrement:before {
  content: "↑";
  font-size: 20px;
  line-height: 30px;
}

.MuiTabs-flexContainer::-webkit-scrollbar-button:end:increment:before {
  content: "↓";
  font-size: 20px;
  line-height: 30px;
} */
@media  (max-width:415px) {
  .headingcalender{
    font-size: 1.7rem !important;
  }
  #selectshow{
    width: 135px !important;
    padding: 10px !important;
    margin-left:0px !important;
    margin-top: 0px;
    /* height: 43px !important; */
    height: 40px !important;

    font-size: 1rem !important;
  }
  /* .serchbarcalender{
    width: 188px !important;

   } */
   .calenderdiv{
    height: 100% !important;
    margin-left: 0px !important;
    width: 100% !important;
    /* flex-direction: column;
  margin-left: -100px !important; */
   }
   .calenderimg{
    margin-left: 10px !important;
    margin-top: 9px !important;
   }


  
   .text3{
    margin-left: 25px !important;
    font-weight: 1.2rem !important;
   }
   .text2{
    margin-left: 57px !important;
    font-size: 1.2rem !important;

   }
   .text4{
    font-weight: 1.2rem;
   }
   .plusimg{
    /* width: 28px !important; */
    margin-top: -87px !important;
    margin-right: -169px !important;
    height: 35px !important;
   }
   .infoicon{
    margin-top: -29px;
    margin-right: -44px;
   }
   .spantxtactive{
      font-size:1rem  !important;
   }
   .spannumactive{
    font-size: 1rem !important;
   }
   .colortab{
    margin-left: 8px !important;
   }
   .spannum{
    font-size: 1rem !important;
   }
   .spantxt{
    font-size: 1rem !important;
   }
   .month{
    margin-left: 0px !important;
   }
   .selectcountry{
    width: 51px !important;
   }
   .calendercolor{
    width: 35px !important;
    height: 35px !important;
    position: relative;
    top: 13px ;
   }
   .serchbar{
    height: 43px !important;
   }
}
@media (min-width:416px) and (max-width:546px){

  .headingcalender{
    font-size: 1.7rem !important;
  }
  #selectshow{
    width: 135px !important;
    padding: 10px !important;
    margin-left:-10px !important;
    margin-top: 0px;
    height: 43px !important;
    font-size: 1rem !important;
  }
  /* .serchbarcalender{
    width: 308px !important;
   } */
   .calenderdiv{
    height: 140px !important;
    margin-left: 0px !important;
    width: 100% !important;
    
   }
   .calenderimg{
    margin-left: 10px !important;
    margin-top: 9px !important;
   }


   .text{
    font-size: 1rem !important;
    /* margin-left: 25px !important; */
    
   }
   .text3{
    margin-left: 25px !important;
   }
   .text2{
    margin-left: 57px !important;
   }
   .plusimg{
    /* width: 28px !important; */
    margin-top: -138px !important;
    margin-right: -224px !important
   }
   .plusimg{
    /* width: 28px !important; */
    margin-top: -87px !important;
    margin-right: -236px !important;
    height: 40px !important;
   }
   .infoicon{
    margin-top: -29px;
    margin-right: -44px;
   }
   .spantxtactive{
      font-size:1rem  !important;
   }
   .spannumactive{
    font-size: 1rem !important;
   }
   .colortab{
    margin-left: 8px !important;
   }
   .spannum{
    font-size: 1rem !important;
   }
   .spantxt{
    font-size: 1rem !important;
   }
   .month{
    margin-left: 0px !important;
   }
   .selectcountry{
    width: 51px !important;
   }
   .calendercolor{
    width: 35px !important;
    height: 32px !important;
    position: relative;
    top: 13px ;
   }
   .serchbar{
    height: 43px !important;
   }


}

@media (min-width:547px) and (max-width:764px){
  .headingcalender{
    font-size: 1.7rem !important;
  }
  #selectshow{
    width: 135px !important;
    padding: 10px !important;
    margin-left:-10px !important;
    margin-top: 0px;
    height: 43px !important;
    font-size: 1rem !important;
  }
  /* .serchbarcalender{
    width: 350px !important;
   } */
   .calenderdiv{
    height: 140px !important;
    margin-left: 0px !important;
    width: 100% !important;
    
   }
   .calenderimg{
    margin-left: 10px !important;
    margin-top: 9px !important;
   }


   .text{
    font-size: 1rem !important;
    /* margin-left: 25px !important; */
    
   }
   .text3{
    margin-left: 25px !important;
   }
   .text2{
    margin-left: 57px !important;
   }
   .plusimg{
    width: 28px !important;
    margin-top: -138px !important;
    margin-right: -224px !important
   }
   .plusimg{
    width: 28px !important;
    margin-top: -87px !important;
    margin-right: -236px !important;
    height: 40px !important;
   }
   .infoicon{
    margin-top: -29px;
    margin-right: -38px;
   }
   .spantxtactive{
      font-size:1rem  !important;
   }
   .spannumactive{
    font-size: 1rem !important;
   }
   .colortab{
    margin-left: 8px !important;
   }
   .spannum{
    font-size: 1rem !important;
   }
   .spantxt{
    font-size: 1rem !important;
   }
   .month{
    margin-left: 0px !important;
   }
   .selectcountry{
    width: 51px !important;
   }
   .calendercolor{
    width: 35px !important;
    height: 32px !important;
    position: relative;
    top: 13px ;
   }
   .serchbar{
    height: 43px !important;
   }



}

@media (min-width:921px) and (max-width:995px){
  /* .serchbarcalender{
    width: 528px !important;
   } */
   .calenderdiv{
    height:100% !important;
    margin-left: 0px !important;
    width: 100% !important;
    
   }
   .calenderimg{
    margin-left: 35px !important;
    margin-top: 9px !important;
   }

}

/* @media (min-width:591px) and (max-width:767px)
{
  .headingcalender{
    font-size: 1.7rem !important;
  }
  #selectshow{
    width: 135px !important;
    padding: 10px !important;
    margin-left:-41px !important;
    margin-top: 0px;
    height: 43px !important;
    font-size: 1rem !important;
  }
  .serchbarcalender{
    width: 28px !important;
   }
   .serchbar{
    width: 100px !important;
   }
   .calenderdiv{
    height: 140px !important;
    margin-left: 0px !important;
    width: 100% !important;
 
   }
   .calenderimg{
    margin-left: 10px !important;
    margin-top: 9px !important;
   }


   .text{
    font-size: 1rem !important;
    
    
   }
   .text3{
    margin-left: 25px !important;
   }
   .text2{
    margin-left: 57px !important;
   }
   .plusimg{
    width: 28px !important;
    margin-top: -138px !important;
    margin-right: -239px !important
   }
   .spantxtactive{
      font-size:1rem  !important;
   }
   .spannumactive{
    font-size: 1rem !important;
   }
   .colortab{
    margin-left: 8px !important;
   }
   .spannum{
    font-size: 1rem !important;
   }
   .spantxt{
    font-size: 1rem !important;
   }
   .month{
    margin-left: 0px !important;
   }
   .selectcountry{
    width: 51px !important;
   }
   .calendercolor{
    width: 35px !important;
    height: 32px !important;
    position: relative;
    top: 13px ;
   }
   .serchbar{
    height: 43px !important;
   }
} */

@media (max-width: 920px) and (min-width: 765px){
  #selectshow{
    width: 147px !important;
  }
  /* .serchbarcalender{
    width: 550px !important;
   } */
   .calenderimg{
    margin-left: 45px !important;
   }
   .calenderdiv{
    height: 140px !important;
    margin-left: 0px !important;
    width: 100% !important;
    /* flex-direction: column;
  margin-left: -100px !important; */
   }
}

@media (max-width:756px) and (min-width:554px) {
  #selectshow{
    width: 135px !important;
    padding: 10px !important;
    margin-left:-10px !important;
    /* margin-top: 5px; */
  }
  /* .serchbarcalender{
    width: 428px !important;
   } */
   .calenderdiv{
    height: 100% !important;
    margin-left: 0px !important;
    width: 100% !important;
    /* flex-direction: column;
  margin-left: -100px !important; */
   }
   .calenderimg{
    margin-left: 10px !important;
   }
   .text{
    font-size: 1rem !important;
   }
   .plusimg{
    width: 28px !important;
   }
   .month{
    margin-left: 0px !important;
   }
   .selectcountry{
    width: 51px !important;
   }
   .calendercolor{
    width: 35px !important;
    height: 32px !important;
   }
}
@media (max-width:280px) and (min-width:270px) {
  #selectshow{
    width: 135px !important;
    padding: 10px !important;
    margin-left:-10px !important;
    margin-top: 5px;
  }
  /* .serchbarcalender{
    width: 155px !important;
   } */
   .calenderdiv{
    height: 100% !important;
    /* margin-left: 0px !important; */
    width: 100% !important;
    /* flex-direction: column;
  margin-left: -100px !important; */
   }
   .calenderimg{
    margin-left: 10px !important;
   }
   .text{
    font-size: 1rem !important;
   }
   .plusimg{
    width: 28px !important;
   }
   .month{
    margin-left: 0px !important;
   }
   .selectcountry{
    width: 51px !important;
   }
   .calendercolor{
    width: 35px !important;
    height: 32px !important;
   }
}
@media (max-width:1196px) and (min-width:996px) {
  #selectshow{
    width: 135px !important;
    padding: 10px !important;
    margin-left:-10px !important;
    margin-top: 5px;
  }
  /* .serchbarcalender{
    width: 795px !important;
   } */
   .calenderdiv{
    height: 100% !important;
    margin-left: 0px !important;
    width: 100% !important;
    /* flex-direction: column;
  margin-left: -100px !important; */
   }
   .calenderimg{
    margin-left: 10px !important;
   }
   .text{
    font-size: 1rem !important;
   }
   .plusimg{
    /* width: 28px !important; */
   }
   .month{
    margin-left: 0px !important;
   }
   .selectcountry{
    width: 51px !important;
   }
   .calendercolor{
    width: 35px !important;
    height: 32px !important;
   }
}





@media (max-width:764px) {

  .text3{
    font-weight: 600 !important;
    font-size: 1.2rem !important;
  }
  .text4{
    font-weight: 600 !important;
    font-size: 1.2rem !important;
  }
  .text2{
    font-weight: 400 !important;
  }
  .plusimg{
    width: 35px !important;
    height: 35px !important;
  }
  .finalrow{
    gap: 5px !important;
  }

  
}
@media (max-width:756px) {
  .text3{
    font-weight: 600 !important;
    font-size: 1.2rem !important;
  }
  .text4{
    font-weight: 600 !important;
    font-size: 1.2rem !important;
  }
  .text2{
    font-weight: 400 !important;
  }
  .plusimg{
    width: 35px !important;
    height: 35px !important;
  }
  .finalrow{
    gap: 5px !important;
  }

  /* .serchbarcalender{
    width: 350px !important;
  } */
}



@media (max-width:593px) {

  .text3{
    font-weight: 600 !important;
    font-size: 1.2rem !important;
  }
  .text4{
    font-weight: 600 !important;
    font-size: 1.2rem !important;
  }
  .text2{
    font-weight: 400 !important;
  }
  .plusimg{
    width: 35px !important;
    height: 35px !important;
  }
  .finalrow{
    gap: 5px !important;
  }
  .serchbarcalender_main_outside{
    width: 100% !important;
  }
 
  /* .serchbarcalender{
    width: 380px !important;
  } */
}

@media (max-width:545px) {

  .finalrow{
    gap: 4px !important;
  }
  
}
@media (max-width:540px) {
  .finalrow{
    gap: 10px !important;
  }
  
}
@media (max-width:535px) {

  .finalrow{
    gap: 10px !important;
  }
  
}
/* @media (max-width:510px) {

  .serchbarcalender{
    width: 350px !important;
  }
 
  
}
@media (max-width:500px) {

  .serchbarcalender{
    width: 350px !important;
  }
 
  
}

@media (max-width:545px) and (min-width:511px) {
  .serchbarcalender {
    width: 340px !important;
}
  
}
@media (max-width:484px) and (min-width:464px) {
  .serchbarcalender {
    width: 330px !important;
}
  
}


@media (max-width:464px) and (min-width: 420px) {

  .serchbarcalender{
    width: 270px !important;
  }
 
  
}
@media (max-width:419px) and (min-width: 400px) {

  .serchbarcalender{
    width: 230px !important;
  }
 
  
}
@media (max-width:400px) and (min-width: 380px) {

  .serchbarcalender{
    width: 240px !important;
  }
 
  
}
@media (max-width:379px) and (min-width: 350px) {

  .serchbarcalender{
    width: 200px !important;
  }
 
  
} */




/* @media (max-width: 550px) and (min-width: 500px){
  #selectshow{
    width: 135px !important;
    padding: 10px !important;
    margin-left:160px !important;
    margin-top: 5px;
  }
  .serchbar{
    width: 373px;
  }
  .serchbarcalender{
    width: 228px !important;
   }
   .calenderdiv{
    height: 100% !important;
    margin-left: 0px !important;
    width: 100% !important;
 
   }
   .calenderimg{
    margin-left: 10px !important;
   }
   .text{
    font-size: 1rem !important;
   }
   .plusimg{
    width: 28px !important;
   }
   .month{
    margin-left: 0px !important;
   }
   .selectcountry{
    width: 51px !important;
   }
   .calendercolor{
    width: 35px !important;
    height: 32px !important;
   }
}
 */




/* @media (max-width:414px) {
  #selectshow{
    width: 172px !important;
  }
  .serchbarcalender{
    width: 228px !important;
   }
  .calenderdiv{
    height: 100% !important;
   }
   .calenderimg{
    margin-left: 10px !important;
   }

}

@media (max-width:390px) {
  #selectshow{
    width: 137px !important;
  }
  .serchbarcalender{
    width: 228px !important;
   }
}


@media (max-width:393px) {
  #selectshow{
    width: 137px !important;
  }
  .serchbarcalender{
    width: 235px !important;
   }
}

@media (max-width:360px) {
  #selectshow{
    width: 137px !important;
  }
  .serchbarcalender{
    width: 210px !important;
   }
} */





@media (max-width:1199px)  {
  .serchbarcalender{
    width: 80% !important;
  }
}
@media (max-width:1195px)  {
  .serchbarcalender{
    width: 84% !important;
  }
}
@media (max-width:995px) {
  .serchbarcalender{
    width: 80% !important;
  }
}
@media (max-width:991px) {
  .serchbarcalender{
    width: 72% !important;
  }
}
@media (max-width:768px) and (min-width: 763px){
  .serchbarcalender{
    width: 70% !important;
  }
}
@media (max-width:499px) and (min-width: 466px){
  .serchbarcalender{
    width: 70% !important;
  }
}
@media (max-width:466px) and (min-width: 450px){
  .serchbarcalender{
    width: 66% !important;

}
}
@media (max-width:449px) and (min-width: 410px){
  .serchbarcalender{
    width: 65% !important;

}
}
@media (max-width:415px) and (min-width: 365px){
  .serchbarcalender{
    width: 59% !important;

}
}
@media (max-width:365px) and (min-width: 335px){
  .serchbarcalender{
    width: 54% !important;

}
}




/* @media (max-width:820px) {
  #selectshow{
    width: 137px !important;
  }
  .serchbarcalender{
    width: 550px !important;
   }
   .calenderimg{
    margin-left: 45px !important;
   }

}

@media (max-width:912px) {
  #selectshow{
    width: 137px !important;
  }
  .serchbarcalender{
    width: 550px !important;
   }
   .calenderimg{
    margin-left: 45px !important;
   }

} */